import styled from 'styled-components'
import { Trigger, Content, Item } from '@radix-ui/react-select'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  height: 100vh;
`

export const Header = styled.div`
  display: flex;
  justify-content: start;
  flex-direction: row;
  align-items: center;
  padding: 1.8rem 1rem;
  gap: 8px;
  background-color: ${({ theme }) => theme.colors.guto.BaseAzul2};
  height: 10vh;
`

export const BoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  height: 90vh;
  background-color: ${({ theme }) => theme.colors.guto.FundoAzul};
`

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px;
  background-color: ${({ theme }) => theme.colors.guto.FundoAzul};
`

export const Title = styled.h1`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.Text};
`

export const Subtitle = styled.h2`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.Text};
`

export const ContainerConfig = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  padding: 12px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`

export const SelectTrigger = styled(Trigger)`
  display: flex;
  align-items: center;
  border: none;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.guto.Azul2};
  color: ${({ theme }) => theme.colors.Text};
  border-radius: 8px;
  padding: 8px;
  gap: 8px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`

export const SelectContent = styled(Content)`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.guto.Azul2};
  border-radius: 8px;
  padding: 8px;
  gap: 8px;
  z-index: 999;
  margin-left: 58.5vw;
  border: none;
  box-shadow: 0px 0px 1px 0px black;
`

export const SelectItem = styled(Item)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 8px;
  cursor: pointer;
  transition: background-color 0.2s;
  color: ${({ theme }) => theme.colors.Text};
  &:hover {
    background-color: ${({ theme }) => `${theme.colors.guto.Azul3}70`};
  }
`
export const Separator = styled.div`
  width: 95%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.SeparatorOnFundoAzul};
  align-self: center;
`
