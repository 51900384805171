import React, { useEffect, useState } from 'react'
import {
  Container,
  ContactImage,
  DivColumn,
  DivRow,
  NameContact,
  IconsEnd,
  ModalImage,
  EditButton,
  ModalImageSection,
  ModalNameSection,
  ModalNumberSection,
  InputBox,
  InputLabel,
  MoreVertIconStyled,
  CaretContainer,
  CaretLeftStyled,
  UserListStyled,
  TagStyled,
  SyncButton,
  ErrorMessage,
  ArchiveButton,
  ButtonGroup,
  NameContactEnter,
} from './styles'
import Dialog from '../../../../../../shared/components/Dialog'
import imageContato from '../../../../../../assets/images/imageContato'
import {
  extractImage,
  formatarDataParaBrasil,
} from '../../../../../../utils/format'
import DialogUsers from './components/DialogUsers'
import DialogTags from './components/DialogTags'
import ContactService from '../../../../../../shared/services/Contact/ContactService'
import FileServcie from '../../../../../../shared/services/File/FileService'
import { useImage } from '../../../../../../hook/image'
import { useTriggers } from '../../../../../../hook/triggers'
import { useSession } from '../../../../../../hook/session'

const ContactSuperior = ({
  socket,
  dataContact,
  setNavigation,
  lateralContacts,
  setLateralContacts,
  handleArchiveContact,
}) => {
  const {
    isOpenLocal,
    setIsOpenLocal,
    isOpenTags,
    setIsOpenTags,
    isOpenUsers,
    setIsOpenUsers,
    isOpenEdit,
    setIsOpenEdit,
  } = useTriggers()
  const [editedNames, setEditedNames] = useState({
    [dataContact.id]: dataContact.name,
  })
  const [error, setError] = useState('')
  const { contactImage, setContactImage } = useImage()
  const [nameContact, setNameContact] = useState(dataContact.name)
  const { verifyIsRoot } = useSession()
  const [contactDateEnter, setContactDateEnter] = useState({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const getEnterDate = async () => {
      const response = await ContactService.getEnterDate(dataContact.id)
      setContactDateEnter(response.data)
      setLoading(false)
    }
    getEnterDate()
  }, [dataContact.id])

  useEffect(() => {
    if (!isOpenLocal) {
      setNameContact('')
    }
  }, [isOpenLocal])

  useEffect(() => {
    if (dataContact && dataContact.name) {
      setEditedNames((prevNames) => ({
        ...prevNames,
        [dataContact.id]: dataContact.name,
      }))
    }
  }, [dataContact])

  useEffect(() => {
    if (!isOpenLocal) {
      setIsOpenEdit(false)
      setError('')
    }
  }, [isOpenLocal])

  const handleOpenModal = () => {
    setIsOpenLocal(true)
  }

  const handleSyncImage = async () => {
    try {
      const response = await ContactService.syncImageUser(dataContact.id)
      const filename = response.data.picture_filename_contact
      const filetype = response.data.picture_filetype_contact
      const resp = await FileServcie.getSrc(filename, filetype)
      setContactImage(dataContact.id, resp.data.base64)
    } catch (error) {
      console.log(error)
    }
  }

  const editContact = () => {
    setIsOpenEdit(!isOpenEdit)
    setError('')
  }

  const handleSave = () => {
    const newName = nameContact
    if (!newName.trim()) {
      setError('O nome não pode estar vazio.')
      return
    }
    setError('')
    setIsOpenEdit(false)
    socket.emit('editContact', { id: dataContact.id, newName })
    setEditedNames((prevNames) => ({ ...prevNames, [dataContact.id]: newName }))
  }

  const renderEnterDate = () => {
    if (verifyIsRoot()) {
      return (
        <div>
          <span>{loading ? 'Carregando...' : 'Entrou em: '}</span>
        </div>
      )
    } else {
      return (
        <div>
          <span>{loading ? 'Carregando...' : 'Atribuído em: '}</span>
        </div>
      )
    }
  }

  return (
    <Container>
      <DivRow>
        <CaretContainer onClick={() => setNavigation('contacts')}>
          <CaretLeftStyled weight="bold" />
        </CaretContainer>
        {extractImage(contactImage[dataContact.id]) !== 'null' ? (
          <ContactImage
            src={contactImage[dataContact.id]}
            alt="Imagem do contato"
          />
        ) : (
          <ContactImage src={imageContato} alt="Imagem do contato" />
        )}
        <DivColumn>
          <NameContact>
            {editedNames[dataContact.id] ?? dataContact.name}
          </NameContact>
        </DivColumn>
      </DivRow>
      <IconsEnd>
        <TagStyled onClick={() => setIsOpenTags(true)} />
        {verifyIsRoot() && (
          <UserListStyled onClick={() => setIsOpenUsers(true)} />
        )}
        <MoreVertIconStyled onClick={handleOpenModal} />
      </IconsEnd>
      <DialogTags
        id={dataContact.id}
        open={isOpenTags}
        onChangeOpen={setIsOpenTags}
        contact={dataContact}
        lateralContacts={lateralContacts}
        setLateralContacts={setLateralContacts}
      />
      <DialogUsers
        id={dataContact.id}
        open={isOpenUsers}
        onChangeOpen={setIsOpenUsers}
        contact={dataContact}
      />
      <Dialog open={isOpenLocal} onChangeOpen={setIsOpenLocal}>
        <ModalImageSection>
          {extractImage(contactImage[dataContact.id]) !== 'null' ? (
            <ModalImage
              src={contactImage[dataContact.id]}
              alt="Imagem do contato"
            />
          ) : (
            <ModalImage src={imageContato} alt="Imagem do contato" />
          )}
        </ModalImageSection>
        <ModalNameSection>
          <NameContactEnter>
            {renderEnterDate()}
            <span>
              {formatarDataParaBrasil(contactDateEnter.contactEnterDate)}
            </span>
          </NameContactEnter>
        </ModalNameSection>
        <ModalNameSection>
          {isOpenEdit ? (
            <>
              <ButtonGroup>
                <SyncButton onClick={handleSyncImage}>
                  Sincronizar Imagem
                </SyncButton>
                {dataContact.isArchivedContact ? (
                  <ArchiveButton
                    onClick={() => {
                      handleArchiveContact(dataContact.id, dataContact.name)
                    }}
                  >
                    Desarquivar contato
                  </ArchiveButton>
                ) : (
                  <ArchiveButton
                    onClick={() => {
                      handleArchiveContact(
                        dataContact.id,
                        dataContact.name,
                        !dataContact.isArchivedContact,
                      )
                    }}
                  >
                    Arquivar contato
                  </ArchiveButton>
                )}
              </ButtonGroup>
              <InputLabel>Nome</InputLabel>
              <InputBox
                maxLength={20}
                value={nameContact}
                onChange={(e) => setNameContact(e.target.value)}
              />
              {error && <ErrorMessage>{error}</ErrorMessage>}
            </>
          ) : (
            <DivColumn>
              <NameContact>
                {editedNames[dataContact.id] ||
                  dataContact.name ||
                  'Nome Indisponível'}
              </NameContact>
            </DivColumn>
          )}
        </ModalNameSection>
        <ModalNumberSection>{dataContact.number}</ModalNumberSection>
        <EditButton onClick={isOpenEdit ? handleSave : editContact}>
          {isOpenEdit ? 'Salvar' : 'Editar'}
        </EditButton>
      </Dialog>
    </Container>
  )
}

export default ContactSuperior
