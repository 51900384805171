import styled from 'styled-components'

export const Tag = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 8px;
  padding: 1rem;
  border-radius: 8px;
  background-color: ${({ theme, $isSelected }) =>
    $isSelected ? theme.colors.guto.Azul1 : theme.colors.guto.Azul2};
  transition: background-color 0.1s ease-in;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme, $isSelected }) =>
      $isSelected ? theme.colors.guto.Azul1 : `${theme.colors.guto.Azul3}70`};
  }
`

export const TagActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`

export const TagText = styled.p`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.Text};
`

export const TagAbbreviation = styled.div`
  background-color: ${({ $color }) => $color};
  padding: 0.3rem;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const TextAbbreviation = styled.p`
  font-size: 1rem;
  font-weight: bold;
  color: ${({ $color }) => $color};
`

export const BoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  height: 90vh;
  background-color: ${({ theme }) => theme.colors.guto.FundoAzul};
`
