import React, { useEffect, useState } from 'react'
import {
  Container,
  LeftContainer,
  RightContainer,
  Header,
  Title,
  Subtitle,
  ListUsers,
  User,
  UserText,
  RootIcon,
  ListContacts,
  Contact,
  ContactText,
  DivRowContact,
  StyledTrash,
  DivRowSupContacts,
  StyledPlusCircle,
  HeaderContact,
  ContactNotFound,
  UserActions,
  StyledPencil,
  BoxContainer,
} from './styles.js'
import UserService from '../../../../../../shared/services/User/UserService.js'
import ContactService from '../../../../../../shared/services/Contact/ContactService.js'
import Input from '../../../../../../shared/components/Input/index.jsx'
import DialogContact from './components/DialogContacts/index.jsx'
import DialogUsers from './components/DialogUsers/index.jsx'
import DialogUsersEdit from './components/DialogUserEdit/index.jsx'
import { useSession } from '../../../../../../hook/session.jsx'
import { useToast } from '../../../../../../hook/toast.jsx'
import { BackButton } from '../../styles.js'
import { CaretLeft } from 'phosphor-react'

const Gerenciamento = ({ socket, setNavigation }) => {
  const { getSessionData } = useSession()
  const [updateContacts, setUpdateContacts] = useState(false)
  const [updateUsers, setUpdateUsers] = useState(false)
  const [openDialogUsersEdit, setOpenDialogUsersEdit] = useState(false)
  const [openDialogContacts, setOpenDialogContacts] = useState(false)
  const [openDialogUsers, setOpenDialogUsers] = useState(false)
  const [users, setUsers] = useState([])
  const [contacts, setContacts] = useState([])
  const [selectedUser, setSelectedUser] = useState(null)
  const [searchUser, setSearchUser] = useState('')
  const [searchContact, setSearchContact] = useState('')
  const [user, setUser] = useState(null)
  const { addToast } = useToast()

  useEffect(() => {
    const getUsers = async () => {
      const response = await UserService.getUsers()
      setUsers(response.data)
    }
    getUsers()
  }, [updateUsers, getSessionData])

  useEffect(() => {
    const getUserContacts = async () => {
      const response = await ContactService.getUserContactsByUser(selectedUser)
      setContacts(response.data)
    }
    if (selectedUser) {
      getUserContacts()
    }
  }, [selectedUser, updateContacts])

  const filteredUsers = users.filter((user) =>
    user.email_user.toLowerCase().includes(searchUser.toLowerCase()),
  )
  const filteredContacts = contacts.filter((contact) => {
    const name = contact.full_name_contact.toLowerCase()
    const number = contact.id_contact.substring(0, 12).toLowerCase()
    const search = searchContact.toLowerCase()
    return name.includes(search) || number.includes(search)
  })

  const handleDeleteUser = async (user) => {
    const response = await UserService.deleteUser(user.id_user)
    if (response.status === 204) {
      const newUsers = users.filter((u) => u.id_user !== user.id_user)
      setUsers(newUsers)
      addToast({
        type: 'success',
        title: 'Usuário deletado com sucesso',
        description: 'O usuário foi deletado com sucesso',
      })
    } else {
      addToast({
        type: 'error',
        title: 'Erro ao deletar usuário',
        description: 'Ocorreu um erro ao deletar o usuário',
      })
    }
  }

  const handleOpenEdit = (user) => {
    setUser(user)
    setOpenDialogUsersEdit(true)
  }

  const renderContacts = () => {
    if (contacts.length > 0) {
      return filteredContacts.map((contact) => (
        <Contact key={contact.id_contact}>
          <DivRowContact>
            <ContactText>
              {contact.full_name_contact} -{' '}
              {contact.id_contact.substring(0, 12)}
            </ContactText>
            <StyledTrash
              size={24}
              onClick={() => handleDeleteContact(contact)}
            />
          </DivRowContact>
        </Contact>
      ))
    } else {
      return <ContactNotFound>Nenhum contato encontrado</ContactNotFound>
    }
  }

  const handleDeleteContact = async (contact) => {
    const response = await ContactService.deleteUserContact(
      contact.id_users_contacts,
    )
    if (response.status === 204) {
      const newContacts = contacts.filter(
        (c) => c.id_contact !== contact.id_contact,
      )
      setContacts(newContacts)
      addToast({
        type: 'success',
        title: 'Contato deletado!',
        description: 'O contato foi deletado com sucesso',
      })
    } else {
      addToast({
        type: 'error',
        title: 'Erro ao deletar contato',
        description: 'Ocorreu um erro ao deletar o contato',
      })
    }
  }

  if (users) {
    return (
      <Container style={{ width: '100%' }}>
        <Header>
          <BackButton onClick={() => setNavigation('configList')}>
            <CaretLeft
              size={32}
              weight="bold"
              style={{
                marginTop: '0.4rem',
              }}
            />
          </BackButton>
          <Title>Gerenciamento</Title>
        </Header>
        <BoxContainer>
          <LeftContainer>
            <HeaderContact>
              <Subtitle>Usuários</Subtitle>
            </HeaderContact>
            <ListUsers>
              <DivRowSupContacts>
                <Input
                  placeholder="Pesquisar usuários"
                  onChange={(e) => setSearchUser(e.target.value)}
                />
                <StyledPlusCircle
                  size={24}
                  onClick={() => setOpenDialogUsers(true)}
                />
                <DialogUsers
                  open={openDialogUsers}
                  onChangeOpen={setOpenDialogUsers}
                  update={updateUsers}
                  setUpdate={setUpdateUsers}
                />
                <DialogUsersEdit
                  socket={socket}
                  open={openDialogUsersEdit}
                  onChangeOpen={setOpenDialogUsersEdit}
                  user={user}
                  update={updateUsers}
                  setUpdate={setUpdateUsers}
                />
              </DivRowSupContacts>
              {filteredUsers.map((user) => (
                <User
                  $isSelected={user.id_user === selectedUser}
                  key={user.id_user}
                  onClick={() => setSelectedUser(user.id_user)}
                >
                  <UserActions>
                    <UserText>{user.email_user}</UserText>
                    {user.is_root_user && <RootIcon size={10} />}
                  </UserActions>
                  <UserActions>
                    <StyledPencil
                      size={24}
                      onClick={() => handleOpenEdit(user)}
                    />
                    <StyledTrash
                      size={24}
                      onClick={() => handleDeleteUser(user)}
                    />
                  </UserActions>
                </User>
              ))}
            </ListUsers>
          </LeftContainer>
          <RightContainer>
            <HeaderContact>
              <Subtitle>Contatos do usuário selecionado</Subtitle>
            </HeaderContact>
            <ListContacts>
              {selectedUser && (
                <DivRowSupContacts>
                  <Input
                    placeholder="Pesquisar contatos"
                    onChange={(e) => setSearchContact(e.target.value)}
                  />
                  <StyledPlusCircle
                    size={24}
                    onClick={() => setOpenDialogContacts(true)}
                  />
                  <DialogContact
                    id={selectedUser}
                    open={openDialogContacts}
                    onChangeOpen={setOpenDialogContacts}
                    update={updateContacts}
                    setUpdate={setUpdateContacts}
                    contactsSelecteds={contacts}
                  />
                </DivRowSupContacts>
              )}
              {renderContacts()}
            </ListContacts>
          </RightContainer>
        </BoxContainer>
      </Container>
    )
  }
}

export default Gerenciamento
