import styled from 'styled-components'
import IconDrop from '../../../../../../assets/images/icon_drop.jsx'
import * as Tooltip from '@radix-ui/react-tooltip'
import * as ContextMenu from '@radix-ui/react-context-menu'
import { Trash, Pencil, Heart } from 'phosphor-react'

export const HeartStyled = styled(Heart)`
  width: 1rem;
  height: 1rem;
  margin-left: 0.5rem;
  color: ${({ theme }) => theme.colors.Text};
`
export const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`

export const DateBox = styled.div`
  display: ${(props) => (props.$isFlag ? 'block' : 'none')};
  color: ${({ theme }) => theme.colors.Outline};
  font-size: 0.8rem;
`

export const Container = styled.div`
  display: ${(props) => (props.$isFlag ? 'none' : 'flex')};
  flex-direction: row;
  justify-content: ${(props) => (props.$isMine ? 'flex-end' : 'flex-start')};
  align-items: center;
  width: 95%;
`

export const ImageMine = styled.img`
  display: ${(props) => (props.$isMine ? 'block' : 'none')};
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
`

export const ImageNotMine = styled.img`
  display: ${(props) => (props.$isMine ? 'none' : 'block')};
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
`

export const IconMine = styled(IconDrop)`
  display: ${(props) => (props.$isMine ? 'block' : 'none')};
  rotate: -90deg;
  margin-left: -0.1rem;
  width: 1.7rem;
  min-width: 1.7rem;
  fill: ${(props) => {
    if (props.$isNoteMessage) {
      return props.theme.colors.guto.Laranja
    }
    return props.theme.colors.guto.Azul1
  }};
`
export const EditedMessage = styled.i`
  font-size: 0.8rem;
  color: ${({ theme }) => theme.colors.Text};
`

export const IconNotMine = styled(IconDrop)`
  display: ${(props) => (props.$isMine ? 'none' : 'block')};
  rotate: 90deg;
  margin-right: -0.1rem;
  fill: ${({ theme }) => theme.colors.guto.BaseAzul2};
  width: 1.7rem;
  min-width: 1.7rem;
`

// props.$isMine ? '#E4E1EC' : 'white'

export const ContainerMessage = styled.main`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.$isMine ? 'flex-end' : 'flex-start')};
  justify-content: start;
  max-height: 70vh;
  overflow-y: auto;
  padding: 0.5rem;
  border-radius: 10px;
  background-color: ${(props) => {
    if (props.$isMine) {
      if (props.$isNoteMessage) {
        return props.theme.colors.guto.Laranja
      }
      return props.theme.colors.guto.Azul1
    }
    return props.theme.colors.guto.BaseAzul2
  }};
  margin: 0.5rem 0;
  word-wrap: break-word;
  word-break: break-all;

  /* Estilo personalizado para a barra de rolagem */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    //deixe o fundo um pouco mais escuro
    background: ${({ theme }) => theme.colors.Track};
    border-radius: 0 10px 10px 0;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.Thumb};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  p {
    color: ${({ theme }) => theme.colors.Text};
    font-size: 1rem;
    line-height: 1.4;
    word-break: break-word;
    text-align: left;
  }

  .image-message {
    display: flex;
    flex-direction: column;
    align-items: ${(props) => (props.$isMine ? 'flex-end' : 'flex-start')};
    max-width: 100%;
    margin-top: 0.5rem;
    border-radius: 5px;
    overflow: hidden;

    img {
      width: 200px;
      height: 200px;
      border-radius: 5px;
      object-fit: cover;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    p {
      margin-top: 0.5rem;
      font-size: 0.9rem;
      color: ${({ theme }) => theme.colors.Outline};
      background-color: rgba(0, 0, 0, 0.05);
      padding: 0.2rem 0.5rem;
      border-radius: 3px;
      max-width: 100%;
      word-break: break-word;
    }
  }
`

export const DragBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 400px;
  max-height: 200px; /* Define uma altura máxima fixa */
  margin: 1rem 0;
  padding: 1rem;
  border: 2px dashed ${({ theme }) => theme.colors.Outline};
  border-radius: 5px;
  position: relative;

  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    border-radius: 5px;
  }
`

export const RemoveButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  background: ${({ theme }) => theme.colors.Alert};
  border: none;
  color: white;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

export const SendMessages = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 1rem;
  border-top: 1px solid ${({ theme }) => theme.colors.Outline};
  background: ${({ theme }) => theme.colors.BackgroundMessage};
  position: relative;
  overflow: hidden; /* Adiciona rolagem se necessário */
`

export const DragDropArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 2rem;
  border: 2px dashed ${({ theme }) => theme.colors.Outline};
  border-radius: 5px;
  background: ${({ theme }) => theme.colors.BackgroundMessageStrong};
`

export const Input = styled.input`
  flex: 1;
  padding: 0.5rem;
  border: 1px solid ${({ theme }) => theme.colors.Outline};
  border-radius: 5px;
  margin-right: 1rem;
`

export const DownloadMessage = styled.div`
  display: flex;
  position: relative;
  margin-top: 0.5rem;
  font-size: 0.9rem;
  color: ${({ theme }) => theme.colors.Outline};
  background-color: rgba(0, 0, 0, 0.05);
  padding: 0.2rem 0.5rem;
  border-radius: 3px;
  max-width: 100%;
  word-break: break-word;
  p {
    cursor: pointer;
  }
`

export const TimeDifference = styled.span`
  font-size: 0.6rem;
  color: ${({ theme }) => theme.colors.Outline};
`

export const TooltipContent = styled(Tooltip.Content)`
  background-color: ${({ theme }) => theme.colors.Tooltip};
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.8rem;
  max-width: 50rem;
`

export const TooltipArrow = styled(Tooltip.Arrow)`
  fill: ${({ theme }) => theme.colors.Tooltip};
`

export const MessageImg = styled.img`
  &.landscape {
    max-height: 50vh;
    max-width: 30vw;
  }
  &.portrait {
    max-width: 25vw;
    max-height: 63vh;
  }
  object-fit: cover;
  border-radius: 5px;
  cursor: pointer;

  @media (max-width: 800px) {
    &.landscape {
      max-height: 70vh;
      max-width: 50vw;
    }
    &.portrait {
      max-width: 35vw;
      max-height: 63vh;
    }
  }
`

export const MessageVideo = styled.video`
  &.landscape {
    max-height: 50vh;
  }
  &.portrait {
    max-width: 25vw;
    max-height: 63vh;
  }
  object-fit: cover;
  border-radius: 5px;
  cursor: pointer;

  @media (max-width: 800px) {
    &.landscape {
      max-height: 70vh;
      max-width: 50vw;
    }
    &.portrait {
      max-width: 35vw;
      max-height: 63vh;
    }
  }
`

export const Sticker = styled.img`
  width: 10vw;
  object-fit: cover;
  border-radius: 5px;
  @media (max-width: 800px) {
    width: 20vw;
  }
`

export const SeeMedia = styled.div`
  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
  }
`

export const VCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background: ${({ theme }) => theme.colors.BaseAzul2};
  gap: 0.5rem;
  margin-bottom: 3px;
  box-shadow:
    0 4px 6px rgba(0, 0, 0, 0.1),
    0 1px 3px rgba(0, 0, 0, 0.1);
`

export const VCardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;
`

export const VCardImage = styled.img`
  width: 3vw;
  border-radius: 50%;
`

export const VCardName = styled.p`
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
`

export const VCardPhone = styled.p`
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
`

export const VCardButton = styled.button`
  background: ${({ theme }) => theme.colors.guto.Azul2};
  width: 100%;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0px 0px 5px 5px;
  cursor: pointer;
  transition: background 0.2s ease-in;
  &:hover {
    background: ${({ theme }) => theme.colors.guto.Azul1};
  }
  font-weight: 400;
`

export const ContextMenuContent = styled(ContextMenu.Content)`
  background: ${({ theme }) => theme.colors.guto.Azul2};
  color: ${({ theme }) => theme.colors.Text};
  border-radius: 5px;
  padding: 0.5rem;
  z-index: 100;
  width: ${(props) => (props.$isMenuLateral ? '15vw' : '10vw')};
  max-width: 90%;
  min-width: 150px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  @media (max-width: 1150px) {
    width: 15vw;
  }

  @media (max-width: 760px) {
    width: 20vw;
  }
`

export const ContextMenuItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: background 0.2s ease-in;
  border-radius: 5px;
  &:hover {
    background: ${({ theme }) => theme.colors.guto.Azul3 + '70'};
  }
`

export const ContextMenuItem = styled(ContextMenu.Item)`
  padding: 0.5rem;
`

export const TrashStyled = styled(Trash)`
  width: 1.5rem;
  height: 1.5rem;
  padding: 0.1rem;
  margin-right: 0.3rem;
  color: ${({ theme }) => theme.colors.Alert};
`

export const PencilStyled = styled(Pencil)`
  width: 1.5rem;
  height: 1.5rem;
  padding: 0.1rem;
  margin-right: 0.3rem;
  color: ${({ theme }) => theme.colors.Text};
`
