import styled from 'styled-components'

export const Div = styled.div`
  display: flex;
  flex-direction: column;
`

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 1rem;
  width: 50%;
  height: 100%;
  @media (max-width: 800px) {
    width: 100%;
  }
`

export const RecContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 0.5rem;
`

export const Line = styled.hr`
  color: ${({ theme }) => theme.colors.Text};
  margin: 10px 20px;
  border-radius: 10px;
`

export const SelectContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: row;
`

export const Button = styled.button`
  background-color: ${(props) =>
    props.secondary
      ? props.theme.colors.guto.Vermelho
      : props.theme.colors.guto.Verde + '99'};
  color: ${({ theme }) => theme.colors.Text};
  padding: 10px 15px;
  margin: 5px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: all 0.3s ease;

  &:hover {
    opacity: 0.8;
  }
`

export const AudioPreview = styled.div`
  margin-top: 20px;
`

export const AudioContainerRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`

export const AudioPlayer = styled.audio`
  width: 100%;
  margin-top: 10px;
`

export const SaveButton = styled(Button)`
  width: 40%;
  align-self: center;
  background-color: ${({ theme }) => theme.colors.guto.Azul1};
  transition: all 0.3s ease;

  &:hover {
    opacity: 0.8;
  }
`

export const MicIcon = styled.div`
  display: inline-block;
  color: ${(props) =>
    props.$isRecording ? props.theme.colors.Alert : props.theme.colors.Text};
  font-size: 20px;
`

export const ErrorsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const ErrorSpan = styled.span`
  color: red;
`

export const BoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;
  height: 90vh;
  background-color: ${({ theme }) => theme.colors.guto.FundoAzul};

  @media (max-width: 800px) {
    flex-direction: column;
  }
`
