import { Container } from './styles'
import { useRef, useEffect } from 'react'

const InputChat = ({
  type,
  placeholder,
  handleSendMessage,
  conversationId,
  draft,
  ...res
}) => {
  const textareaRef = useRef(null)

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (e.shiftKey) {
        return
      }
      e.preventDefault()
      handleSendMessage()
    }
  }

  const handleInput = () => {
    const textarea = textareaRef.current
    textarea.style.height = 'auto'
    textarea.style.height = `${textarea.scrollHeight}px`
    if (!textarea.value) {
      textarea.style.height = 'auto'
    }
  }

  useEffect(() => {
    const textarea = textareaRef.current
    if (textarea) {
      textarea.value = draft || ''
      textarea.style.height = 'auto'
      textarea.style.height = `${textarea.scrollHeight}px`
    }
  }, [conversationId, draft])

  return (
    <Container>
      <textarea
        ref={textareaRef}
        type={type}
        placeholder={placeholder}
        onKeyDown={handleKeyDown}
        onInput={handleInput}
        rows={1}
        {...res}
      />
    </Container>
  )
}

export default InputChat
