import styled from 'styled-components'

export const Schedule = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.guto.Azul2};
  padding: 1rem;
  border-radius: 8px;
  width: 100%;
  gap: 8px;
  transition: background-color 0.1s ease-in;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colors.guto.Azul3 + '70'};
  }
`

export const ScheduleActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`

export const ScheduleContainerText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const ScheduleText = styled.p`
  font-size: 0.9rem;
  color: ${({ theme }) => theme.colors.Text};
  font-weight: 400;
`
