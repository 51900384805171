import styled from 'styled-components'
import * as Popover from '@radix-ui/react-popover'
import { FunnelSimple, XCircle, SignOut } from 'phosphor-react'
import { Archive } from '@mui/icons-material'
import * as Switch from '@radix-ui/react-switch'

export const ArchiveIconStyled = styled(Archive)`
  color: ${({ theme, isArchived }) =>
    isArchived ? theme.colors.guto.Vermelho : theme.colors.Text};
  cursor: pointer;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.8;
  }
`

export const ArchiveText = styled.p`
  color: ${({ theme }) => theme.colors.Text};
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  input {
    width: 100%;
    height: 40px;
    border: 1px solid ${({ theme }) => theme.colors.Primary};
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
  }
`

export const HeaderConfig = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${({ theme }) => theme.colors.guto.BaseAzul1};
  justify-content: center;
  align-items: center;
`

export const BottomConfig = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.guto.BaseAzul1};
  padding: 10px;
  flex-direction: column;
`

export const ImageContact = styled.img`
  width: auto;
  max-width: 70px;
  min-width: 70px;
  border-radius: 100%;
  padding: 0.5rem;
`

export const NameSession = styled.p`
  color: ${({ theme }) => theme.colors.Text};
  font-size: 1.3rem;
  font-weight: bold;
  margin-left: 0.5rem;
`

export const IconsEnd = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
  padding-right: 0.5rem;
`

export const PopoverContent = styled(Popover.Content)`
  background-color: ${({ theme }) => theme.colors.guto.Azul2};
  border-radius: 5px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: white;
`

export const PopoverArrow = styled(Popover.Arrow)`
  fill: ${({ theme }) => theme.colors.guto.Azul2};
`

export const Item = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 5px;
  transition: 0.3s ease;
  padding: 5px;
  border-radius: 5px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.PopoverHover};
  }
`

export const ItemText = styled.p`
  cursor: pointer;
`

export const FunnelSimpleStyled = styled(FunnelSimple)`
  color: ${({ theme }) => theme.colors.Text};
  cursor: pointer;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.8;
  }
`

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const TitleFilter = styled.p`
  font-size: 1rem;
  font-weight: bold;
`

export const TagAbbreviation = styled.div`
  background-color: ${({ $color }) => $color};
  padding: 0.3rem;
  border-radius: 4px;
  display: flex;
  justify-content: ${({ $selected }) =>
    $selected ? 'space-around' : 'center'};
  gap: 5px;
  align-items: center;
  cursor: pointer;
  transition: 0.3s ease;

  &:hover {
    opacity: 0.8;
  }
`

export const TextAbbreviation = styled.p`
  font-size: 1rem;
  font-weight: bold;
  color: ${({ $color }) => $color};
`

export const XCircleStyled = styled(XCircle)`
  color: ${({ $color }) => $color};
  cursor: pointer;
  transition: 0.3s ease;
  width: 20px;

  &:hover {
    color: red;
  }
`

export const BottomConfigSup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
`

export const SelectedTagsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  flex-wrap: wrap;
`

export const ClearFilters = styled.p`
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: 0.3s ease;

  &:hover {
    color: red;
  }
`

export const SignOutStyled = styled(SignOut)`
  color: ${({ theme }) => theme.colors.Text};
  cursor: pointer;
  transition: 0.3s ease;

  &:hover {
    color: ${({ theme }) => theme.colors.Primary};
  }
`

export const ContainerTags = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 15rem;
  gap: 5px;
`

export const SwitchRoot = styled(Switch.Root)`
  all: unset;
  width: 42px;
  height: 25px;
  background-color: black;
  border-radius: 9999px;
  position: relative;
  box-shadow: 0 2px 10px var(--black-a7);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  &:focus {
    outline: none;
    box-shadow: none;
  }
  &[data-state='checked'] {
    background-color: black;
  }
`

export const SwitchThumb = styled(Switch.Thumb)`
  display: block;
  width: 21px;
  height: 21px;
  background-color: white;
  border-radius: 9999px;
  box-shadow: 0 2px 2px var(--black-a7);
  transition: transform 100ms;
  transform: translateX(2px);
  outline: none;
  will-change: transform;
  &:focus {
    outline: none;
    box-shadow: none;
  }
  &[data-state='checked'] {
    transform: translateX(19px);
  }
`

export const SwitchContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  justify-content: center;
  align-items: center;
`

export const LabelSwitch = styled.p`
  color: ${({ theme }) => theme.colors.Text};
  font-size: 1rem;
`
