import React from 'react'
import * as Dialog from '@radix-ui/react-dialog'
import {
  ModalOverlay,
  ModalContent,
  CloseButton,
  ModalImage,
  ModalVideo,
} from './style'

const DialogSeeMedia = ({ open, onOpenChange, mediaSrc, type }) => {
  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      <Dialog.Portal>
        <ModalOverlay>
          <ModalContent>
            <CloseButton onClick={() => onOpenChange(false)}>
              &times;
            </CloseButton>
            {type === 'image' ? (
              <ModalImage src={mediaSrc} alt="Selected Media" />
            ) : (
              <ModalVideo src={mediaSrc} alt="Selected Media" controls />
            )}
          </ModalContent>
        </ModalOverlay>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

export default DialogSeeMedia
