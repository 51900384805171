import { BrowserRouter } from 'react-router-dom'
import Rotas from './routes/index'
import GlobalStyles from './styles/global'
import { ThemeProvider } from './hook/theme'
import { SessionProvider } from './hook/session'
import { TriggersProvider } from './hook/triggers'

function App() {
  return (
    <>
      <GlobalStyles />
      <BrowserRouter>
        <TriggersProvider>
          <SessionProvider>
            <ThemeProvider>
              <Rotas />
            </ThemeProvider>
          </SessionProvider>
        </TriggersProvider>
      </BrowserRouter>
    </>
  )
}

export default App
