import { useEffect, useState } from 'react'
import imageContato from '../../../../../../assets/images/imageContato'
import {
  extractImage,
  formatLastMessageTimeStamp,
  getTextColor,
  getFirstNChars,
} from '../../../../../../utils/format'
import {
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuItemContainer,
  PencilStyled,
} from '../../../Chat/components/Message/styles'
import {
  ArrowsClockwiseStyled,
  TagStyled,
  UserListStyled,
} from '../../../Chat/components/ContactSuperior/styles'
import {
  BlocoContact,
  DivRow,
  DivColumn,
  ContactImage,
  CountMessages,
  LastIcons,
  ImageIconWrapper,
  Div,
  ContainerTags,
  DivTag,
  TagAbbreviation,
  TextAbbreviation,
  SubTitle,
  ArchiveIconStyled,
  UnarchiveIconStyled,
} from './styles'
import { useImage } from '../../../../../../hook/image'
import {
  ChatText,
  FileAudio,
  ImageSquare,
  FileText,
  VideoCamera,
} from 'phosphor-react'
import * as ContextMenu from '@radix-ui/react-context-menu'
import { useTriggers } from '../../../../../../hook/triggers'
import ContactService from '../../../../../../shared/services/Contact/ContactService'
import FileService from '../../../../../../shared/services/File/FileService'

const ContactLateral = ({
  contact,
  selectedContact,
  lastMessage,
  typeMessage,
  onContactClick,
  handleArchiveContact,
  typeSearch,
}) => {
  const { setIsOpenTags, setIsOpenUsers, setIsOpenLocal, searchedMsg } =
    useTriggers()
  const [unreadMessages, setUnreadMessages] = useState(
    contact.unreadMessageCount,
  )
  const { contactImage, setContactImage } = useImage()

  const handleSyncImage = async () => {
    try {
      const response = await ContactService.syncImageUser(contact.id)
      const filename = response.data.picture_filename_contact
      const filetype = response.data.picture_filetype_contact
      const resp = await FileService.getSrc(filename, filetype)
      setContactImage(contact.id, resp.data.base64)
    } catch (error) {
      console.log(error)
    }
  }

  const playBeep = () => {
    const beep = new Audio('/notification-sound.mp3')
    beep
      .play()
      .then(() =>
        // eslint-disable-next-line
        console.log('audio ok'))
      .catch((err) => console.log(err))
  }

  useEffect(() => {
    const notificationTimeout = setTimeout(() => {
      if (
        unreadMessages < contact.unreadMessageCount &&
        (selectedContact === null || selectedContact !== contact.id)
      ) {
        setUnreadMessages(contact.unreadMessageCount)
        Notification.requestPermission()
          .then((permission) => {
            if (permission === 'granted') {
              navigator.serviceWorker.ready.then((registration) => {
                if (document.visibilityState === 'hidden') {
                  registration.showNotification('Nova mensagem', {
                    body: 'nova mensagem',
                    icon: '/android/android-launchericon-192-192.png',
                    badge: '/android/android-launchericon-72-72.png',
                  })
                } else {
                  playBeep()
                }
              })
            }
          })
          .catch((error) => console.log('Erro ao solicitar permissão: ', error))
      }
    }, 300)

    return () => clearTimeout(notificationTimeout)
  }, [contact.unreadMessageCount, selectedContact, contact.id, unreadMessages])

  useEffect(() => {
    if (selectedContact === contact.id) {
      setUnreadMessages(0)
    }
  }, [selectedContact, contact.id])

  const renderTags = () => {
    if (!contact.tags) {
      return null
    }
    return (
      <DivTag>
        {contact.tags.map((tag) => (
          <TagAbbreviation key={tag.id_tag} $color={tag.color_hex_tag}>
            <TextAbbreviation $color={() => getTextColor(tag.color_hex_tag)}>
              {tag.abbreviation_tag}
            </TextAbbreviation>
          </TagAbbreviation>
        ))}
      </DivTag>
    )
  }

  const messageIcons = {
    chat: <ChatText style={{ width: '20px' }} />,
    audio: <FileAudio style={{ width: '20px' }} />,
    image: <ImageSquare style={{ width: '20px' }} />,
    document: <FileText style={{ width: '20px' }} />,
    video: <VideoCamera style={{ width: '20px' }} />,
    ptt: <FileAudio style={{ width: '20px' }} />,
  }

  const checkSelected = () => {
    if (typeSearch === 'contacts') {
      return selectedContact === contact.id ? 'true' : 'false'
    } else if (typeSearch === 'messages') {
      return searchedMsg.lastMessageId === contact.lastMessageId
        ? 'true'
        : 'false'
    }
  }

  if (contact) {
    return (
      <BlocoContact
        onClick={() => onContactClick(contact.id)}
        $isSelected={checkSelected()}
      >
        <ContextMenu.Root>
          <ContextMenu.Trigger>
            <DivRow>
              {extractImage(contactImage[contact.id]) !== 'null' ? (
                <ContactImage
                  src={contactImage[contact.id]}
                  alt="Imagem do contato"
                />
              ) : (
                <ContactImage src={imageContato} alt="Imagem do contato" />
              )}
              <DivColumn>
                <p>{contact.name}</p>
                <Div>
                  {lastMessage === '' && typeMessage === '' ? (
                    <ImageIconWrapper>
                      <span>Nova Conversa</span>
                    </ImageIconWrapper>
                  ) : lastMessage === '' ? (
                    <>
                      {messageIcons[typeMessage]}
                      <SubTitle>
                        {typeMessage === 'chat'
                          ? 'Nova mensagem'
                          : 'Novo arquivo'}
                      </SubTitle>
                    </>
                  ) : typeMessage === 'vcard' ? (
                    <SubTitle>Contato: {lastMessage.split(';')[0]}</SubTitle>
                  ) : (
                    <>
                      {messageIcons[typeMessage]}
                      <SubTitle>
                        {lastMessage.length < 19
                          ? lastMessage.replace(/[*_~`]+/g, '')
                          : `${getFirstNChars(
                              lastMessage,
                              contact.name.length,
                            )}...`}
                      </SubTitle>
                    </>
                  )}
                </Div>
              </DivColumn>
              <ContainerTags>{renderTags()}</ContainerTags>
              <LastIcons>
                <p>
                  {formatLastMessageTimeStamp(contact.lastMessageTimestamp)}
                </p>
                {unreadMessages &&
                !contact.isViewedMessage &&
                contact.isViewedMessage !== null ? (
                  <CountMessages></CountMessages>
                ) : null}
              </LastIcons>
            </DivRow>
          </ContextMenu.Trigger>
          <ContextMenuContent $isMenuLateral={true}>
            <ContextMenuItemContainer>
              <ContextMenuItem onClick={() => handleSyncImage()}>
                Sincronizar imagem
              </ContextMenuItem>
              <ArrowsClockwiseStyled
                style={{
                  marginRight: '5',
                }}
              />
            </ContextMenuItemContainer>
            {contact.isArchivedContact ? (
              <ContextMenuItemContainer
                onClick={() => handleArchiveContact(contact.id, contact.name)}
              >
                <ContextMenuItem>Desarquivar contato</ContextMenuItem>
                <UnarchiveIconStyled />
              </ContextMenuItemContainer>
            ) : (
              <ContextMenuItemContainer
                onClick={() =>
                  handleArchiveContact(
                    contact.id,
                    contact.name,
                    !contact.isArchivedContact,
                  )
                }
              >
                <ContextMenuItem>Arquivar contato</ContextMenuItem>
                <ArchiveIconStyled />
              </ContextMenuItemContainer>
            )}
            <ContextMenuItemContainer onClick={() => setIsOpenTags(true)}>
              <ContextMenuItem>Atribuir tags</ContextMenuItem>
              <TagStyled
                style={{
                  marginRight: '5',
                }}
              />
            </ContextMenuItemContainer>
            <ContextMenuItemContainer onClick={() => setIsOpenUsers(true)}>
              <ContextMenuItem>Atribuir a usuário</ContextMenuItem>
              <UserListStyled
                style={{
                  marginRight: '5',
                }}
              />
            </ContextMenuItemContainer>
            <ContextMenuItemContainer onClick={() => setIsOpenLocal(true)}>
              <ContextMenuItem>Editar contato</ContextMenuItem>
              <PencilStyled />
            </ContextMenuItemContainer>
          </ContextMenuContent>
        </ContextMenu.Root>
      </BlocoContact>
    )
  }
}

export default ContactLateral
