import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`

export const DialogContainer = styled.div`
  background-color: white;
  padding: 1.5rem 4rem 1rem 4rem;
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  width: 25rem;
  background-color: ${({ theme }) => theme.colors.guto.Azul2};

  > svg {
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.Text};
    &:hover {
      color: ${({ theme }) => theme.colors.TextHover};
    }
  }

  @media (max-width: 768px) {
    width: 90vw;
  }
`

export const DialogOverlay = styled.div`
  background: rgba(0 0 0 / 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  place-items: center;
  overflow-y: auto;
  z-index: 1000;
`
