export default {
  colors: {
    Primary: '#e4e1ec',
    BackgroundMessage: '#f6f2f7',
    BackgroundMessageStrong: '#f9f9f9',
    BackgroundMessageStrongOverflow: '#f6f2f7',
    Outline: '#777',
    Text: '#333',
    TextHover: '#444',
    Alert: '#ff5f5f',
    Success: '#4CAF50',
    Warning: '#ffcc00',
    Jit: '#5000ff',
    JitHover: '#4d00e6',
    TextOnJit: '#fff',
    White: '#fff',
    OnPrimary: '#242424',
    DividerContact: '#e4e1ec',
    WhiteHover: '#f0f0f0',
    MainScreen: '#fcf8fd',
    Popover: '#333',
    PopoverHover: '#444',
    ImageBackground: '#fff',
    Track: '#e4e1ec',
    Thumb: '#999',
    Tooltip: '#242424',
    SelectedContact: '#ccc',
    Note: 'orange',
    Button: '#afa2eb',
    guto: {
      Azul1: '#0065FF',
      FundoAzul: '#081830',
      BaseAzul1: '#092652',
      Azul2: '#1A4689',
      Azul3: '#063A88',
      Verde: '#33D24D',
      Azul4: '#10BBFF',
      Vermelho: '#EA3B3B',
      Laranja: '#F57124',
    },
  },
}
