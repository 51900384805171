import React, { createContext, useState, useContext, useEffect } from 'react'
import LocalStorage from '../utils/LocalStorage'

const ThemeContext = createContext()

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState('dark')

  useEffect(() => {
    const localTheme = LocalStorage.getValue('theme')
    if (localTheme) {
      setTheme(localTheme)
    }
  }, [])

  const handleThemeChange = (newTheme) => {
    setTheme(newTheme)
    LocalStorage.setValue('theme', newTheme)
  }

  return (
    <ThemeContext.Provider value={{ theme, handleThemeChange }}>
      {children}
    </ThemeContext.Provider>
  )
}

export const useTheme = () => {
  const context = useContext(ThemeContext)
  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider')
  }
  return context
}
