import { api } from '../../api/axios'

export default new (class MessageApi {
  async deleteMessage(id) {
    try {
      return api.delete(`message/${id}`)
    } catch (error) {
      console.log(error)
      return error
    }
  }

  async updateMessage(id, data) {
    try {
      return api.put(`message/${id}`, data)
    } catch (error) {
      console.log(error)
      return error
    }
  }
})()
