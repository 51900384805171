import { api } from '../../api/axios'

export default new (class StickerApi {
  async Favorite(data) {
    try {
      return api.post(`stickers/`, data)
    } catch (error) {
      console.log(error)
      return error
    }
  }
})()
