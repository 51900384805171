import MessageApi from './api/MessageApi'

class MessageService {
  async delete(id) {
    try {
      return MessageApi.deleteMessage(id)
    } catch (error) {
      return error
    }
  }

  async update(id, data) {
    try {
      return MessageApi.updateMessage(id, data)
    } catch (error) {
      return error
    }
  }
}

export default new MessageService()
