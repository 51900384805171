import React from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'
import { Bounce, ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import {
  Container,
  Div,
  ContainerLogin,
  InputContainer,
  StyledInput,
  Label,
  Form,
  Title,
  Button,
  ErrorSpan,
  ContainerImg,
  Img,
  // MarkName,
  AStyled,
  LoginDiv,
  Subtitle,
} from './styles'

import { Envelope, Eye, EyeSlash, LockKey } from 'phosphor-react'
import LoginService from '../../shared/services/Login/LoginService'
import LocalStorage from '../../utils/LocalStorage'

const Login = () => {
  const [hoveredMail, setHoveredMail] = React.useState(false)
  const [focusedMail, setFocusedMail] = React.useState(false)
  const [hoveredPassword, setHoveredPassword] = React.useState(false)
  const [focusedPassword, setFocusedPassword] = React.useState(false)
  const [showPassword, setShowPassword] = React.useState(false)

  const toggleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const loginScheme = z.object({
    emailUser: z.string().min(1, 'Email é obrigatório'),
    passwordUser: z.string().min(1, 'Senha é obrigatório'),
  })

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    mode: 'all',
    resolver: zodResolver(loginScheme),
  })

  const handleSubmitLogin = React.useCallback(async (data) => {
    try {
      const response = await LoginService.loginAuth(data)
      if (response.status === 200) {
        await LocalStorage.setValue('token', response.data.token)
        window.location.href = '/'
      } else {
        toast.error('Email e/ou senha incorreto(os)', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
          transition: Bounce,
        })
      }
    } catch (err) {
      console.log(err)
    }
  }, [])

  return (
    <Container>
      <Div>
        <ContainerImg>
          <AStyled href="https://jit.technology/">
            <Img src="guto_login.png" alt="GutoBot" />
          </AStyled>
        </ContainerImg>
        <ContainerLogin>
          <AStyled className="login" href="https://jit.technology/">
            <Img src="guto_login.png" alt="GutoBot" />
          </AStyled>
          <LoginDiv>
            <Title>Entrar</Title>
            <Subtitle>Use seus dados para entrar no sistema.</Subtitle>
            <Form onSubmit={handleSubmit(handleSubmitLogin)}>
              <Label>Email</Label>
              <InputContainer
                style={{ marginBottom: '1.4rem' }}
                $hovered={hoveredMail}
                $focused={focusedMail}
              >
                <Envelope />
                <StyledInput
                  placeholder="Digite seu email"
                  onFocus={() => setFocusedMail(true)}
                  onBlur={() => setFocusedMail(false)}
                  onMouseEnter={() => setHoveredMail(true)}
                  onMouseLeave={() => setHoveredMail(false)}
                  type={'email'}
                  onChange={(e) => register(e)}
                  name={'emailUser'}
                  {...register('emailUser')}
                />
              </InputContainer>
              <ErrorSpan>{errors.email?.message}</ErrorSpan>
              <Label>Senha</Label>
              <InputContainer
                $hovered={hoveredPassword}
                $focused={focusedPassword}
              >
                <LockKey />
                <StyledInput
                  placeholder="Digite sua senha"
                  onFocus={() => setFocusedPassword(true)}
                  onBlur={() => setFocusedPassword(false)}
                  onMouseEnter={() => setHoveredPassword(true)}
                  onMouseLeave={() => setHoveredPassword(false)}
                  type={showPassword ? 'text' : 'password'}
                  onChange={(e) => register(e)}
                  name={'passwordUser'}
                  {...register('passwordUser')}
                />
                {showPassword ? (
                  <EyeSlash onClick={toggleShowPassword} />
                ) : (
                  <Eye onClick={toggleShowPassword} />
                )}
              </InputContainer>
              <ErrorSpan style={{ marginTop: '10.2rem' }}>
                {errors.password?.message}
              </ErrorSpan>
              <Button type="submit">Entrar</Button>
            </Form>
          </LoginDiv>
        </ContainerLogin>
      </Div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        transition={Bounce}
      />
    </Container>
  )
}

export default Login
