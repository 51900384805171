import { useCallback, useEffect, useState } from 'react'
import {
  Container,
  DialogContainer,
  DialogOverlay,
  Email,
  NameEmailSection,
} from './styles'
import * as DialogRadix from '@radix-ui/react-dialog'
import { X } from 'phosphor-react'
import {
  Contact,
  ContactNotFound,
  ContactText,
  DivRowContact,
  DivRowSupContacts,
  ListContacts,
  StyledPlusCircle,
  StyledTrash,
  Subtitle,
  Title,
  UserActions,
} from '../../../../../../../Settings/components/MainScreen/components/Gerenciamento/styles'
import Input from '../../../../../../../../shared/components/Input'
import UserService from '../../../../../../../../shared/services/User/UserService'
import ContactService from '../../../../../../../../shared/services/Contact/ContactService'
import { useToast } from '../../../../../../../../hook/toast'
import DialogContacts from './components/DialogContacts'
import { Header } from '../DialogTags/components/DialogAddTags/styles'
import { Button, DivButton } from './components/DialogContacts/styles'
import LoadingSpinner from '../../../../../../../../shared/components/LoadingSpinner'
import { useTheme } from 'styled-components'

const Dialog = ({ open, onChangeOpen, showIconClose = true, id, contact }) => {
  const theme = useTheme()
  const { addToast } = useToast()
  const [users, setUsers] = useState([])
  const [searchUser, setSearchUser] = useState('')
  const [openDialog, setOpenDialog] = useState(false)
  const [update, setUpdate] = useState(false)
  const [loading, setLoading] = useState(true)

  const filteredUsers = users.filter((user) => {
    const email = user?.email_user?.toLowerCase()
    const name = user?.full_name_user?.toLowerCase()
    return (
      email.includes(searchUser.toLowerCase()) ||
      name.includes(searchUser.toLowerCase())
    )
  })

  useEffect(() => {
    if (!open) {
      setLoading(true)
      setUsers([])
    }
  }, [open])

  useEffect(() => {
    const getUsers = async () => {
      const response = await UserService.getUserByIdContact(id)
      if (response.data.users) {
        setUsers(response.data.users)
      }
      setLoading(false)
    }
    getUsers()
  }, [id, update, contact])

  const handleDeleteUser = async (user) => {
    const response = await ContactService.deleteUserContact(
      user.id_users_contacts,
    )
    if (response.status === 204) {
      addToast({
        type: 'success',
        title: 'Sucesso',
        description: 'Usuario deletado com sucesso',
      })
      setUsers(users.filter((u) => u.id_user !== user.id_user))
    } else {
      addToast({
        type: 'error',
        title: 'Erro',
        description: 'Erro ao deletar usuario',
      })
    }
  }

  const renderUsers = () => {
    if (loading) {
      return (
        <ContactNotFound>
          <LoadingSpinner strokeColor={theme.colors.Text} />
        </ContactNotFound>
      )
    }

    if (filteredUsers.length > 0) {
      return filteredUsers.map((user) => {
        return (
          <Contact key={user.id_user}>
            <DivRowContact>
              <NameEmailSection>
                <ContactText>{user.full_name_user}</ContactText>
                <Email>{user.email_user}</Email>
              </NameEmailSection>
              <UserActions>
                <StyledTrash size={24} onClick={() => handleDeleteUser(user)} />
              </UserActions>
            </DivRowContact>
          </Contact>
        )
      })
    } else {
      return <ContactNotFound>Nenhum usuário encontrado</ContactNotFound>
    }
  }

  const handleCloseDialog = useCallback(() => {
    onChangeOpen(false)
  }, [onChangeOpen])

  return (
    <DialogRadix.Root open={open} onOpenChange={onChangeOpen}>
      <DialogRadix.Portal>
        <DialogRadix.Overlay asChild>
          <DialogOverlay>
            <DialogRadix.Content asChild>
              <DialogContainer>
                <DialogRadix.Close asChild>
                  {showIconClose && (
                    <X
                      onClick={handleCloseDialog}
                      style={{ cursor: 'pointer' }}
                    />
                  )}
                </DialogRadix.Close>
                <Container>
                  <Header>
                    <Title>Gerenciamento de contato</Title>
                    <Subtitle>
                      Usuários com acesso ao contato: {contact?.name}
                    </Subtitle>
                  </Header>
                  <ListContacts>
                    <DivRowSupContacts>
                      <Input
                        placeholder="Pesquisar usuarios"
                        value={searchUser}
                        onChange={(e) => setSearchUser(e.target.value)}
                      />
                      <StyledPlusCircle
                        style={{ cursor: loading ? 'not-allowed' : 'pointer' }}
                        size={24}
                        onClick={() => setOpenDialog(true)}
                      />
                      <DialogContacts
                        open={openDialog}
                        onChangeOpen={setOpenDialog}
                        id={id}
                        update={update}
                        setUpdate={setUpdate}
                        usersSelecteds={users}
                      />
                    </DivRowSupContacts>
                    {renderUsers()}
                  </ListContacts>
                  <DivButton>
                    <Button onClick={handleCloseDialog}>Fechar</Button>
                  </DivButton>
                </Container>
              </DialogContainer>
            </DialogRadix.Content>
          </DialogOverlay>
        </DialogRadix.Overlay>
      </DialogRadix.Portal>
    </DialogRadix.Root>
  )
}

export default Dialog
