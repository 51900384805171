import React from 'react'
import { Container, Title, DivRow } from './styles'
import { Separator } from '../../../../../Home/components/MenuLateral/components/ContactLateral/styles'

const TabsMenu = ({ tab, setTab, options, setNavigation }) => {
  return (
    <Container>
      {options.map((option, index) => (
        <React.Fragment key={index}>
          <DivRow
            key={index}
            onClick={() => {
              setTab(option.value)
              setNavigation('configScreen')
            }}
            $value={option.value}
            $tab={tab}
          >
            <Title>{option.label}</Title>
          </DivRow>
          <Separator />
        </React.Fragment>
      ))}
    </Container>
  )
}

export default TabsMenu
