import { useCallback, useEffect, useState } from 'react'
import { Container, DialogContainer, DialogOverlay, Header } from './styles'
import * as DialogRadix from '@radix-ui/react-dialog'
import { X } from 'phosphor-react'
import {
  ContactNotFound,
  DivRowSupContacts,
  ListContacts,
  StyledPlusCircle,
  StyledTrash,
  Subtitle,
  Title,
} from '../../../../../../../Settings/components/MainScreen/components/Gerenciamento/styles'
import Input from '../../../../../../../../shared/components/Input'
import {
  Tag,
  TagAbbreviation,
  TagActions,
  TagText,
  TextAbbreviation,
} from '../../../../../../../Settings/components/MainScreen/components/Tags/styles'
import { useToast } from '../../../../../../../../hook/toast'
import DialogTag from './components/DialogAddTags'
import { Button, DivButton } from './components/DialogAddTags/styles'
import TagsService from '../../../../../../../../shared/services/Tags/TagsService'
import { getTextColor } from '../../../../../../../../utils/format'

const Dialog = ({
  open,
  onChangeOpen,
  showIconClose = true,
  id,
  contact,
  lateralContacts,
  setLateralContacts,
}) => {
  const { addToast } = useToast()
  const [tags, setTags] = useState([])
  const [searchTag, setSearchTag] = useState('')
  const [openDialog, setOpenDialog] = useState(false)

  const filteredTags = tags.filter((tag) => {
    const name = tag?.name_tag?.toLowerCase()
    return name.includes(searchTag.toLowerCase())
  })

  useEffect(() => {
    const getTags = async () => {
      if (contact.tags) {
        setTags(contact.tags)
      } else {
        setTags([])
      }
    }
    getTags()
  }, [contact.tags])

  const handleDeleteTag = async (tag) => {
    const response = await TagsService.deleteContactTag(id, tag.id_tag)
    if (response) {
      addToast({
        type: 'success',
        title: 'Sucesso',
        description: 'Tag removida com sucesso',
      })
      setTags(tags.filter((t) => t.id_tag !== tag.id_tag))
      setLateralContacts(
        lateralContacts.map((lateralContact) => {
          if (lateralContact.id === id) {
            return {
              ...lateralContact,
              tags: lateralContact.tags.filter(
                (lateralTag) => lateralTag.id_tag !== tag.id_tag,
              ),
            }
          }
          return lateralContact
        }),
      )
    } else {
      addToast({
        type: 'error',
        title: 'Erro',
        description: 'Erro ao remover tag',
      })
    }
  }

  const renderTags = () => {
    if (filteredTags.length > 0) {
      return filteredTags.map((tag) => {
        return (
          <Tag key={tag.id_tag}>
            <TagActions>
              <TagText>{tag.name_tag}</TagText>
              <TagText>-</TagText>
              <TagAbbreviation $color={tag.color_hex_tag}>
                <TextAbbreviation
                  $color={() => getTextColor(tag.color_hex_tag)}
                >
                  {tag.abbreviation_tag}
                </TextAbbreviation>
              </TagAbbreviation>
            </TagActions>
            <TagActions>
              <StyledTrash size={24} onClick={() => handleDeleteTag(tag)} />
            </TagActions>
          </Tag>
        )
      })
    } else {
      return <ContactNotFound>Nenhuma tag encontrada</ContactNotFound>
    }
  }

  const handleCloseDialog = useCallback(() => {
    onChangeOpen(false)
  }, [onChangeOpen])

  return (
    <DialogRadix.Root open={open} onOpenChange={onChangeOpen}>
      <DialogRadix.Portal>
        <DialogRadix.Overlay asChild>
          <DialogOverlay>
            <DialogRadix.Content asChild>
              <DialogContainer>
                <DialogRadix.Close asChild>
                  {showIconClose && (
                    <X
                      onClick={handleCloseDialog}
                      style={{ cursor: 'pointer' }}
                    />
                  )}
                </DialogRadix.Close>
                <Container>
                  <Header>
                    <Title>Tags</Title>
                    <Subtitle>Tags do contato: {contact?.name}</Subtitle>
                  </Header>
                  <ListContacts>
                    <DivRowSupContacts>
                      <Input
                        placeholder="Pesquisar usuarios"
                        value={searchTag}
                        onChange={(e) => setSearchTag(e.target.value)}
                      />
                      <StyledPlusCircle
                        size={24}
                        onClick={() => setOpenDialog(true)}
                      />
                    </DivRowSupContacts>
                    <DialogTag
                      open={openDialog}
                      onChangeOpen={setOpenDialog}
                      id={id}
                      tagsSelecteds={tags}
                      setTagsSelecteds={setTags}
                      lateralContacts={lateralContacts}
                      setLateralContacts={setLateralContacts}
                    />
                    {renderTags()}
                  </ListContacts>
                  <DivButton>
                    <Button onClick={handleCloseDialog}>Fechar</Button>
                  </DivButton>
                </Container>
              </DialogContainer>
            </DialogRadix.Content>
          </DialogOverlay>
        </DialogRadix.Overlay>
      </DialogRadix.Portal>
    </DialogRadix.Root>
  )
}

export default Dialog
