import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  width: 100%;

  > textarea {
    resize: none;
    overflow: hidden;
    width: 100%;
    max-height: 9vh;
    padding: 0.5rem;
    border: 0px;
    border-radius: 0.5rem;
    background-color: ${({ theme }) => theme.colors.guto.Azul2};
    color: ${({ theme }) => theme.colors.Text};
    &::placeholder {
      opacity: 0.5;
      font-size: 0.85rem;
      color: ${({ theme }) => theme.colors.SubText};
    }
  }
`
