import { api } from '../../api/axios'

export default new (class FileApi {
  async getBase64(filename, filetype) {
    try {
      const body = {
        filename,
        filetype,
      }
      return await api.post('/get-file-as-base64', body, {
        timeout: 5000,
      })
    } catch (error) {
      console.log(error)
      return error
    }
  }
})()
