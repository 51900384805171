import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.guto.BaseAzul1};
`
