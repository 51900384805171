import styled from 'styled-components'
import { Archive, Unarchive } from '@mui/icons-material'

export const BlocoContact = styled.div`
  width: 100%;
  height: 70px;
  //background-color: ${({ theme }) => theme.colors.ImageBackground};
  background-color: ${({ theme, $isSelected }) =>
    $isSelected === 'true'
      ? theme.colors.guto.Azul3
      : theme.colors.guto.BaseAzul1};
  cursor: pointer;
  color: ${({ theme }) => theme.colors.Text};
  transition: all 0.3s;

  &:hover {
    background-color: ${({ theme }) => `${theme.colors.guto.Azul3}70`};
  }
`

export const Separator = styled.div`
  width: 90%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.Separator};
`

export const DivRow = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
  align-items: center;
`

export const DivColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 15px;
  > p {
    color: ${({ theme }) => theme.colors.Text};
    white-space: nowrap;
  }
`

export const ContactImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.DividerContact};
`

export const CountMessages = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.Alert};
  color: ${({ theme }) => theme.colors.Text};
  display: flex;
  justify-content: center;
  align-items: center;
`

export const LastIcons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  > p {
    font-size: 0.8rem;
    color: ${({ theme }) => theme.colors.SubText};
  }
`

export const ImageIconWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.Outline};

  span {
    margin-left: 5px;
    font-size: 14px;
  }
`

export const Div = styled.div`
  display: flex;
  align-items: center;
  transition: all 0.3s;
  gap: 5px;
  color: ${({ theme }) => theme.colors.SubText};
  > p {
    color: ${({ theme }) => theme.colors.Text};
    white-space: nowrap;
  }
`

export const ContainerTags = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
`

export const DivTag = styled.div`
  margin-top: -8px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: start;
  gap: 2px;
  max-height: 60px;
  padding: 0 1rem;
  overflow-y: auto;
`

export const Title = styled.p`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
`
export const TagAbbreviation = styled.div`
  background-color: ${({ $color }) => $color};
  padding: 0 0.3rem;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const TextAbbreviation = styled.p`
  font-size: 0.8rem;
  font-weight: bold;
  color: ${({ $color }) => $color};
  white-space: nowrap;
`

export const SubTitle = styled.h4`
  font-size: 0.8rem;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.SubText};
`

export const ArchiveIconStyled = styled(Archive)`
  color: ${({ theme }) => theme.colors.Text};
  cursor: pointer;
  transition: 0.3s ease;
  margin-right: 5px;
`

export const UnarchiveIconStyled = styled(Unarchive)`
  color: ${({ theme }) => theme.colors.Text};
  cursor: pointer;
  transition: 0.3s ease;
  margin-right: 5px;
`
