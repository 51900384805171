import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  width: 100%;

  > input {
    width: 100%;
    padding: 0.5rem;
    border: 0px;
    border-radius: 0.5rem;
    background-color: ${({ theme }) => theme.colors.guto.FundoAzul};
    color: ${({ theme }) => theme.colors.Text};
    &::placeholder {
      font-size: 0.85rem;
      color: ${({ theme }) => theme.colors.SubText};
    }
  }
`
