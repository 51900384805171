import { PaperPlane, Trash } from 'phosphor-react'
import styled from 'styled-components'

export const Container = styled.div`
  position: absolute;
  z-index: 5;
  bottom: 2.5rem;
  left: 1rem;
  background-color: ${({ theme }) => theme.colors.guto.Azul2};
  padding: 2rem;
  border-radius: 8px;
  overflow: hidden;
  max-height: 70vh;

  @media (max-width: 768px) {
    width: 95vw;
    left: -2rem;
  }
`

export const SearchBar = styled.div`
  margin-bottom: 20px;
`

export const Input = styled.input`
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
`

export const AudiosContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
`

export const AudioItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  @media (max-width: 768px) {
    width: 100%;
  }
`

export const AudioLabel = styled.div`
  color: ${({ theme }) => theme.colors.Text};
  min-width: 8rem;
  @media (max-width: 768px) {
    min-width: 0;
  }
`

export const AudioLabelToolTip = styled.div`
  min-width: 8rem;
`

export const AudioTitle = styled.h2`
  color: ${({ theme }) => theme.colors.Text};
  margin-bottom: 1.5rem;
`

export const AudioPlayer = styled.audio`
  flex: 1;
  @media (max-width: 768px) {
    width: 100%;
    height: 2rem;
  }
`

export const DeleteButton = styled(Trash)`
  color: ${({ theme }) => theme.colors.Text};
`

export const SendAudio = styled(PaperPlane)`
  color: ${({ theme }) => theme.colors.Text};
  cursor: pointer;
  rotate: 90deg;
`

export const Actions = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 5px;
`
