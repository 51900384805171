import React, { useEffect, useState } from 'react'
import {
  Container,
  DivRowSupContacts,
  Header,
  LeftContainer,
  ListUsers,
  StyledPencil,
  StyledPlusCircle,
  StyledTrash,
  Title,
  ContactNotFound,
  MagnifyingGlassPlusStyled,
  BoxContainer,
} from '../Gerenciamento/styles'
import ScheduleService from '../../../../../../shared/services/Schedule/ScheduleService'
import Input from '../../../../../../shared/components/Input'
import DialogAddSchedules from './components/DialogAddSchedules'
import {
  Schedule,
  ScheduleActions,
  ScheduleContainerText,
  ScheduleText,
} from './styles'
import {
  formatDateWithYear,
  formatTimeSchedule,
} from '../../../../../../utils/format'
import { useToast } from '../../../../../../hook/toast'
import DialogSchedule from './components/DialogSchedule'
import { LoadingContainer } from '../../../../../Home/components/Chat/styles'
import LoadingSpinner from '../../../../../../shared/components/LoadingSpinner'
import { useTheme } from 'styled-components'
import { BackButton } from '../../styles'
import { CaretLeft } from 'phosphor-react'

const AgendamentoMensagens = ({ setNavigation }) => {
  const { addToast } = useToast()
  const [searchTerm, setSearchTerm] = useState('')
  const [schedule, setSchedule] = useState([])
  const [openDialog, setOpenDialog] = useState(false)
  const [update, setUpdate] = useState(false)
  const [selectedSchedule, setSelectedSchedule] = useState(null)
  const [openDialogSchedule, setOpenDialogSchedule] = useState(false)
  const [loading, setLoading] = useState(true)
  const theme = useTheme()

  const typeMessageOptions = {
    selecione: 'Selecione',
    chat: 'Chat',
    image: 'Imagem',
    ptt: 'Audio Gravado',
    audio: 'Áudio',
    video: 'Vídeo',
    document: 'Documento',
  }

  useEffect(() => {
    const getSchedules = async () => {
      const response = await ScheduleService.getSchedules()
      setSchedule(response.data)
      setLoading(false)
    }
    getSchedules()
  }, [update])

  const filteredSchedule = () => {
    if (searchTerm) {
      return schedule.filter((schedule) => {
        const message = schedule?.content_message_schedule.toLowerCase()
        const contact = schedule?.to_contact_message_schedule?.toLowerCase()
        const term = searchTerm.toLowerCase()
        if (schedule.length === 0) return false
        return message.includes(term) || contact.includes(term)
      })
    } else {
      return schedule
    }
  }

  const handleDeleteSchedule = async (schedule) => {
    const response = await ScheduleService.deleteSchedule(schedule.id_schedule)
    if (response.status === 204) {
      addToast({
        type: 'success',
        title: 'Sucesso!',
        description: 'Agendamento deletado com sucesso',
      })
      setUpdate(!update)
    } else {
      addToast({
        type: 'error',
        title: 'Erro!',
        description: 'Erro ao deletar agendamento',
      })
    }
  }

  const handleCreateSchedule = () => {
    setOpenDialog(true)
    setSelectedSchedule(null)
  }

  const handleEditSchedule = (schedule) => {
    setSelectedSchedule(schedule)
    setOpenDialog(true)
  }

  const handleSeeMoreSchedule = (schedule) => {
    setOpenDialogSchedule(true)
    setSelectedSchedule(schedule)
  }

  if (loading) {
    return (
      <LoadingContainer>
        <LoadingSpinner strokeColor={theme.colors.Text} />
      </LoadingContainer>
    )
  }

  return (
    <Container
      style={{
        width: '100%',
      }}
    >
      <Header>
        <BackButton onClick={() => setNavigation('configList')}>
          <CaretLeft
            size={32}
            weight="bold"
            style={{
              marginTop: '0.4rem',
            }}
          />
        </BackButton>
        <Title>Agendamento de Mensagem</Title>
      </Header>
      <BoxContainer>
        <LeftContainer>
          <ListUsers $isMobileFullScreen={true}>
            <DivRowSupContacts>
              <Input
                placeholder="Pesquisar Agendamentos"
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <StyledPlusCircle onClick={handleCreateSchedule} />
            </DivRowSupContacts>
            {schedule.length > 0 ? (
              filteredSchedule().map((schedule) => (
                <Schedule key={schedule.id_schedule}>
                  <ScheduleContainerText>
                    <ScheduleText>
                      {schedule.content_message_schedule
                        ? schedule.content_message_schedule.length >= 55
                          ? `Mensagem: ${schedule.content_message_schedule.slice(0, 55)}...`
                          : `Mensagem: ${schedule.content_message_schedule}`
                        : `Mensagem: (${typeMessageOptions[schedule.type_message_schedule]})`}
                    </ScheduleText>
                    <ScheduleText>{`Hora: ${formatTimeSchedule(schedule.timestamp_schedule)}`}</ScheduleText>
                    <ScheduleText>{`Data: ${formatDateWithYear(schedule.timestamp_schedule)}`}</ScheduleText>
                    {schedule.to_contact_message_schedule && (
                      <ScheduleText>
                        {`Para: ${schedule?.to_contact_message_schedule?.split('@')[0]}`}
                      </ScheduleText>
                    )}
                    <ScheduleText>
                      Status:{' '}
                      {schedule.is_processed_schedule ? 'Enviado' : 'Pendente'}
                    </ScheduleText>
                  </ScheduleContainerText>
                  <ScheduleActions>
                    <MagnifyingGlassPlusStyled
                      onClick={() => handleSeeMoreSchedule(schedule)}
                    />
                    <StyledPencil
                      onClick={() => handleEditSchedule(schedule)}
                    />
                    <StyledTrash
                      onClick={() => handleDeleteSchedule(schedule)}
                    />
                  </ScheduleActions>
                </Schedule>
              ))
            ) : (
              <ContactNotFound>Nenhum agendamento encontrado</ContactNotFound>
            )}
          </ListUsers>
        </LeftContainer>
      </BoxContainer>
      <DialogSchedule
        open={openDialogSchedule}
        onChangeOpen={setOpenDialogSchedule}
        schedule={selectedSchedule}
        setSchedule={setSchedule}
        typeMessageOptions={typeMessageOptions}
      />
      <DialogAddSchedules
        open={openDialog}
        onChangeOpen={setOpenDialog}
        update={update}
        setUpdate={setUpdate}
        selectedSchedule={selectedSchedule}
        setSelectedSchedule={setSelectedSchedule}
        typeMessageOptions={typeMessageOptions}
      />
    </Container>
  )
}

export default AgendamentoMensagens
