import StickerApi from './api/StickerApi'

class StickerService {
  async FavoriteSticker(data) {
    try {
      return StickerApi.Favorite(data)
    } catch (error) {
      return error
    }
  }
}

export default new StickerService()
