import EmojiPicker from 'emoji-picker-react'
import { EmojiComponent } from './styles'
import LocalStorage from '../../../../../../utils/LocalStorage'

const EmojiModal = ({ isOpen, onClose, setNewMessage }) => {
  const emojiStyle = LocalStorage.getEmojiStyle()
  if (!isOpen) return null
  return (
    <EmojiComponent>
      <EmojiPicker
        locale="pt"
        width={window.innerWidth < 768 ? '80vw' : 'auto'}
        emojiStyle={emojiStyle}
        theme={'dark'}
        autoFocusSearch={true}
        onEmojiClick={(emojiData) => {
          setNewMessage((prev) => prev + emojiData.emoji)
        }}
      />
    </EmojiComponent>
  )
}

export default EmojiModal
